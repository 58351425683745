<template>
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        class="primary"
        depressed
        @click="$router.push({ name: 'CreateE-Test' })"
      >
        Buat E-Test
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :loading="loadingTable"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        item-key="id"
        class="elevation-1"
      >
        <template #item.startTime="{ item }">
          {{ dateFromEpoch(item.start_time) }}
        </template>
        <template #item.endTime="{ item }">
          {{ dateFromEpoch(item.end_time) }}
        </template>
        <template #item.action="{ item }">
          <v-btn
            class="primary mr-2"
            depressed
            icon
            dark
            small
            @click="$router.push({ name: 'EditEtest', query: { id: item.id } })"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="blue mr-2"
            depressed
            icon
            dark
            small
            @click="$router.push({ name: 'ViewEtest', query: { id: item.id } })"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn
            class="blue-grey darken-4 mr-2"
            depressed
            icon
            dark
            small
            @click="action(item)"
          >
            <v-icon small>mdi-file-document</v-icon>
          </v-btn>
          <v-btn
            class="red accent-3"
            depressed
            icon
            dark
            small
            @click="openDialogDelete(item.id)"
          >
            <v-icon small>mdi-delete-forever</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="dialogDelete" width="300" persistent>
      <v-card>
        <v-card-title class="grey"> Hapus E-Test </v-card-title>
        <v-card-text> Apakah anda ingin menghapus E-Test? </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="indigo" dark outlined @click="dialogDelete = false">
            Kembali
          </v-btn>
          <v-btn
            :loading="loadingDelete"
            class="indigo darken-4"
            dark
            text
            @click="deleteDataEtest()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { getEtestList, deleteEtest } from "@/api/etest";
import moment from "moment/moment";

export default {
  created() {
    this.getDataTable();
  },
  watch: {
    options: {
      handler(param) {
        this.query.page = param.page;
        this.query.limit = param.itemsPerPage;

        this.getDataTable();
      },
      deep: true,
    },
  },
  data() {
    return {
      dialogDelete: false,
      loadingTable: false,
      loadingDelete: false,
      idDelete: null,
      items: [],
      options: {},
      total: 0,
      query: {
        limit: 10,
        page: 1,
      },
      headers: [
        { text: "Judul", value: "title" },
        { text: "Mata Pelajaran", value: "subject" },
        { text: "Tanggal Mulai", value: "startTime" },
        { text: "Tanggal Berakhir", value: "endTime" },
        { text: "Aksi", value: "action", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    action(item) {
      this.$router.push({ name: "ValueList", query: { id: item.id } });
    },
    openDialogDelete(id) {
      this.idDelete = id;
      this.dialogDelete = true;
    },
    getDataTable() {
      this.loadingTable = true;
      getEtestList(this.query)
        .then((res) => {
          const results = res.data;
          if (results.code) {
            this.items = results.data.data;
            this.total = results.data.total;
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    deleteDataEtest() {
      this.loadingDelete = true;
      deleteEtest({ id: this.idDelete })
        .then((res) => {
          const results = res.data;
          if (results.code) {
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: "Berhasil menghapus E-Test",
              color: "success",
            });
          } else {
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: "Gagal menghapus E-Test",
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loadingDelete = false;
          this.dialogDelete = false;
          this.getDataTable();
        });
    },
    dateFromEpoch(date) {
      return moment
        .unix(parseInt(date.toString().substr(0, 10)))
        .format("DD/MM/YYYY, HH:mm");
    },
  },
};
</script>
